<template>
    <main-page>
        <b-container class="text-center">
            <vue-randomator></vue-randomator>
        </b-container>
    </main-page>
</template>

<script>
import VueRandomator from "@/components/vue-randomator.vue";
import MainPage from "@/components/main-page";

export default {
    name: "randomator",
    components: {VueRandomator, MainPage}
}
</script>

<style lang="scss" scoped>
</style>
