<template>
    <div class="randomator">
        <h1 class="my-4 word-art">Choisissez votre cadeau!</h1>
        <div class="gifts">
            <div class="row" v-for="y in itemCount" :key="y">
                <b-btn @click="giftSelected(y * itemCount + x - itemCount)" v-for="x in itemCount" :key="x + '-' + y">
                    <b-img :src="require('../assets/img/gift.png')"></b-img>
                </b-btn>
            </div>
        </div>

        <div v-show="showResult" class="result-box-container">
            <div class="result-box-overlay"></div>
            <div class="result-box">
                <div v-if="playerWon">
                    <h1 class="word-art animate__animated animate__bounceIn animate__delay-1s my-4">Vous avez gagné!</h1>
                    <b-img width="300" class="animate__animated animate__rubberBand" :src="require('../assets/img/jackpot.png')"></b-img>
                </div>
                <div v-else>
                    <h1 class="word-art animate__animated animate__bounceIn animate__delay-1s my-4">Désolé</h1>
                    <b-img width="300" class="animate__animated animate__wobble" :src="require('../assets/img/horse.png')"></b-img>
                </div>
                <div class="text-center mt-4">
                    <b-btn class="animate__animated animate__bounceIn animate__delay-1s" @click="reset">Essayer de nouveau</b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import confetti from "canvas-confetti";

export default {
    name: "vue-randomator",
    data() {
        return {
            audio: {
                win: new Audio('https://freesound.org/data/previews/387/387232_1474204-lq.mp3'),
                insertCoin: new Audio('https://freesound.org/data/previews/276/276091_5123851-lq.mp3'),
            },
            winnerIndex: 0,
            itemCount: 3,
            playerWon: false,
            showResult: false
        };
    },
    mounted() {
        this.reset();
    },
    methods: {
        giftSelected() {
            this.audio.insertCoin.play();
            this.playerWon = Math.random() * 100 < 9;
            this.showResult = true;
            this.playing = false;
            if (this.playerWon) {
                confetti({
                    particleCount: 150,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0, y: 1 }
                });
                // and launch a few from the right edge
                confetti({
                    particleCount: 150,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1, y: 1 }
                });
                this.showFireworks();

                this.audio.win.play();
            }
        },
        showFireworks() {
            let duration = 2 * 1000;
            let animationEnd = Date.now() + duration;
            let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            let interval = setInterval(function() {
                let timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                let particleCount = 50 * (timeLeft / duration);
                // since particles fall down, start a bit higher than random
                confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
                confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
            }, 250);
        },
        reset() {
            this.winnerIndex = Math.round(Math.random() * this.itemCount * this.itemCount);
            this.showResult = false;
            if (confetti) {
                confetti.reset();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
canvas {
    z-index: 999 !important;
}

.randomator {
    padding: 10px;
    position: relative;
    text-align: center;

    .gifts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        width: 660px;
    }
    .row {
        display: flex;
        flex-direction: row;

        .btn {
            width: 200px;
            height: 200px;
            padding: 20px !important;
            margin: 10px !important;
            border-radius: 20px !important;

            img {
                width: 128px;
            }
        }
    }

    .result-box-overlay {
        position: fixed;
        background: rgba(black, 0.7);
        backdrop-filter: blur(10px);
        z-index: 9;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .result-box {
        position: fixed;
        z-index: 10;
        top: 150px;
        left: 150px;
        right: 150px;
        text-align: center;
    }
}
</style>